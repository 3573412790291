<template>
	<div>
		<PageTitle title-name="会話コースチケット一覧" />
		<div :class="$style.searchContainer">
			<vm-text-field v-model="search" label-text="検索" class="searchTextField" @enter="fetchTickets" />
			<VmButton type="primary" @click="fetchTickets" text="検索" />
		</div>
		<v-data-table
			class="dataTable"
			:headers="allConversationTicketHeaders"
			:items="allConversationTicketItems"
			:class="{ 'elevation-1': true }"
			hide-default-footer
			disable-pagination
			disable-sort
			:loading="adminConversationTicketsLoading"
			no-data-text="チケット情報はありません"
			width="20px"
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
			</template>
		</v-data-table>
		<v-pagination :class="$style.pagination" v-model="currentPage" :length="meta.totalPages" @input="fetchTickets" />

		<v-dialog v-model="showEditDialog" max-width="500px">
			<AdminAddFormCard2
				:editedItem="editedItem"
				formTitle="チケット編集"
				buttonTitle="保存"
				@closeAction="closeEditDialog"
				@addAction="saveEdit"
				:loading="adminConversationTicketsLoading"
				noImage
			/>
		</v-dialog>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import { mapGetters, mapActions } from 'vuex'
import VmTextField from '../Atoms/VmTextField'
import AdminAddFormCard2 from '../Organisms/AdminAddFormCard2.vue'
import VmButton from '../Atoms/VmButton.vue'

export default {
	name: 'AdminConversationTicketsList',
	props: {},
	components: {
		PageTitle,
		VmTextField,
		AdminAddFormCard2,
		VmButton
	},
	data: () => ({
		search: '',
		currentPage: 1,
		perPage: 100,
		showEditDialog: false,
		editedItem: {
			ticketId: {
				value: '',
				type: 'display',
				required: false,
				label: 'チケットID'
			},
			currentTicketCount: {
				value: '',
				type: 'number',
				required: true,
				label: '現在のチケット枚数'
			},
			purchasedTicketCount: {
				value: '',
				type: 'number',
				required: true,
				label: '追加チケット枚数'
			}
		}
	}),
	created() {
		this.fetchTickets()
	},
	mounted() {},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			adminConversationTicketsLoading: 'adminConversationTickets/loading',
			allConversationTickets: 'adminConversationTickets/allConversationTickets',
			meta: 'adminConversationTickets/meta'
		}),
		allConversationTicketHeaders() {
			return [
				{ text: 'Actions', value: 'actions', sortable: false },
				{ text: 'id', value: 'id' },
				{ text: '生徒Id', value: 'studentId' },
				{ text: '生徒名', value: 'studentName' },
				{ text: '現在の所持数', value: 'currentTicketCount' },
				{ text: 'フィードバック', value: 'isFeedback' },
				{ text: '有効期限', value: 'expirationDate' },
				{ text: '追加チケット枚数', value: 'purchasedTicketCount' },
				{ text: '追加日', value: 'purchaseDate' },
				{ text: 'ステータス', value: 'status' }
			]
		},
		allConversationTicketItems() {
			if (!this.allConversationTickets) return []

			return this.allConversationTickets.map((ticket) => {
				return {
					id: ticket.id,
					studentId: ticket.studentId,
					studentName: ticket.studentName,
					currentTicketCount: ticket.currentTicketCount,
					isFeedback: ticket.isFeedback ? 'あり' : '-',
					expirationDate: this.$moment(ticket.expirationDate).format('YYYY/MM/DD HH:mm:ss'),
					purchasedTicketCount: ticket.purchasedTicketCount,
					purchaseDate: this.$moment(ticket.purchaseDate).format('YYYY/MM/DD HH:mm:ss'),
					status: ticket.isAvailable ? '有効' : '-'
				}
			})
		}
	},
	methods: {
		...mapActions({
			fetchAllTickets: 'adminConversationTickets/fetchAllTickets',
			updateTickets: 'adminConversationTickets/updateTickets'
		}),
		fetchTickets() {
			this.fetchAllTickets({
				accessToken: this.userInfo.accessToken,
				page: this.currentPage,
				perPage: this.perPage,
				searchText: this.search
			})
		},
		editItem(item) {
			this.editedItem.ticketId.value = item.id
			this.editedItem.purchasedTicketCount.value = item.purchasedTicketCount
			this.editedItem.currentTicketCount.value = item.currentTicketCount
			this.showEditDialog = true
		},
		closeEditDialog() {
			this.showEditDialog = false
		},
		async saveEdit() {
			await this.updateTickets({
				accessToken: this.userInfo.accessToken,
				ticketId: this.editedItem.ticketId.value,
				data: {
					purchasedTicketCount: this.editedItem.purchasedTicketCount.value,
					currentTicketCount: this.editedItem.currentTicketCount.value
				}
			})
			this.fetchTickets()
			this.closeEditDialog()
		}
	}
}
</script>

<style lang="scss" module>
.pagination {
	margin-bottom: 20px;
}
.searchContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
}
</style>

<style lang="scss" scoped>
.searchTextField {
	margin-bottom: 20px;
	max-width: 500px;
}
.dataTable {
	margin-bottom: 32px;
}
</style>
