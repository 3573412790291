<template>
	<div>
		<label v-if="labelText" class="labelText">{{ labelText }}</label>
		<div class="customTextField">
			<input
				class="text-field"
				:type="computedInputType"
				:value="value"
				@input="updateValue($event.target.value)"
				@keyup.enter="onEnter"
			/>
			<div v-if="isPassword" class="toggle-password" @click="toggleShowPass">
				<v-icon :class="showPass ? 'mdi-eye' : 'mdi-eye-off'">{{ showPass ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VmTextField',
	props: {
		value: {
			type: String
		},
		isPassword: {
			type: Boolean,
			default: false
		},
		labelText: {
			type: String,
			default: ''
		},
		inputType: {
			type: String,
			default: 'text'
		}
	},
	data() {
		return {
			showPass: false
		}
	},
	computed: {
		computedInputType() {
			if (this.isPassword) {
				return this.showPass ? 'text' : 'password'
			}

			return this.inputType
		}
	},
	methods: {
		updateValue(value) {
			this.$emit('input', value)
		},
		onEnter() {
			this.$emit('enter')
		},
		toggleShowPass() {
			if (this.isPassword) {
				this.showPass = !this.showPass
			}
		}
	}
}
</script>

<style scoped>
.labelText {
	font-size: 14px;
	margin-bottom: 4px;
}

input {
	height: 50px;
	font-size: 16px;
}

.customTextField {
	position: relative;
	display: flex;
	align-items: center;
}

.text-field {
	width: 100%;
	padding: 8px;
	border: 1px solid #8c8f94;
	border-radius: 4px;
	background-color: white;
	outline: none;
}

.text-field:focus {
	border-width: 2px;
	border-color: #0966ae;
}

.toggle-password {
	position: absolute;
	right: 12px;
	cursor: pointer;
}
</style>
