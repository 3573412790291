import axios from 'axios'

export const adminConversationTickets = {
	namespaced: true,
	state: {
		allConversationTickets: null,
		loading: false,
		meta: null
	},
	getters: {
		loading: (state) => {
			return state.loading
		},
		allConversationTickets: (state) => {
			return state.allConversationTickets
		},
		meta: (state) => {
			return state.meta
		}
	},
	mutations: {
		prepareConnect(state) {
			state.loading = true
		},
		stopLoading(state) {
			state.loading = false
		},
		setAllConversationTicketsData(state, data) {
			state.allConversationTickets = data
		},
		setAllConversationTicketsMeta(state, meta) {
			state.meta = meta
		}
	},
	actions: {
		async fetchAllTickets({ commit }, payload) {
			commit('prepareConnect')
			const { accessToken, page, perPage, searchText } = payload

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
			const params = {
				perPage,
				page,
				searchText
			}
			return axios
				.get(`/api/conversation-lesson-tickets`, { ...header, params })
				.then((res) => {
					commit('setAllConversationTicketsData', res.data.tickets)
					commit('setAllConversationTicketsMeta', res.data.meta)
				})
				.finally(() => {
					commit('stopLoading')
				})
		},
		async updateTickets({}, payload) {
			const { accessToken, ticketId, data } = payload
			const body = {
				purchasedTicketCount: Number(data.purchasedTicketCount),
				currentTicketCount: Number(data.currentTicketCount)
			}

			const header = {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}

			return axios.put(`/api/conversation-lesson-tickets/${ticketId}`, body, header)
		}
	}
}
