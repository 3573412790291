import { render, staticRenderFns } from "./AdminConversationTicketsList.vue?vue&type=template&id=0803396f&scoped=true&"
import script from "./AdminConversationTicketsList.vue?vue&type=script&lang=js&"
export * from "./AdminConversationTicketsList.vue?vue&type=script&lang=js&"
import style0 from "./AdminConversationTicketsList.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./AdminConversationTicketsList.vue?vue&type=style&index=1&id=0803396f&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0803396f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VDataTable,VDialog,VIcon,VPagination})
